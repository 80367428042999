<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">Hosting<br /></h1>
              <h3 class="subtitle has-text-light-grey is-size-5">
                Dedicated and reliable hosting for your web systems.
              </h3>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature-content">
      <h1 class="is-size-4">
        Since 2003 Unified has offered dedicated hosting services to our clients
        for their web systems. Since then, our service offerings have expanded
        to include <span>dedicated physical</span> and
        <span> virtual hosting infrastructure </span> depending on the client's
        requirements. <br /><br />
        Our hosting infrastructure is housed in a <span>state-of-the-art</span>,
        <span>secure</span> and <span>reliable</span> data centre that
        incorporates the following features: <br /><br />
      </h1>
      <hr />
      <h1 class="columns is-size-4">
        <div class="column is-half-tablet is-full-mobile">
          <li>
            <span>Filtered</span> &amp; <span>conditioned</span> power via an
            Uninterruptible Power Supply (UPS), backed up by an
            <span>auto-starting</span> generator &amp; automatic transfer switch
            (ATS). These power systems ensure <span>continuous</span>,
            <span>high-quality</span> power is continually delivered.
          </li>
          <br />
          <li>
            Physically redundant <span>Optic Fibres</span> and a
            <span>gigabit connection</span> to the Western Australian Internet
            Exchange (WAIX).
          </li>
          <br />
          <li>
            Redundant application &amp; <span>database servers</span> and
            <span>backups</span>.
          </li>
          <br />
          <li>
            Very Early Smoke Detector Apparatus (VESDA) smoke detection system,
            capable of detecting <span>microscopic particles of smoke</span>.
            This allows the <span>earliest possible</span> notification of a
            developing fire.
          </li>
          <br />
        </div>
        <div class="column is-half-tablet is-full-mobile">
          <li>
            FM200 waterless fire suppression system to
            <span>quickly eliminate</span> any fire threat within
            <span>seconds</span>, and <span>minimise</span> damage to equipment.
          </li>
          <br />
          <li>
            Redundant precision cooling air conditioning systems with
            <span> automatic failover </span>, to maintain the temperature &amp;
            <span>relative humidity</span> within the data centre.
          </li>
          <br />
          <li>
            Physical access is <span>monitored</span> &amp;
            <span>controlled</span> by closed-circuit television cameras (CCTV).
          </li>
          <br />
          <li>
            <span>24/7 monitored security</span> coded proximity card system to
            control &amp; log visitor access.
          </li>
          <br />
          <li>
            The result has <span>exceptional reliability</span> &amp;
            <span>performance</span> for clients.
          </li>
        </div>
      </h1>
    </section>
  </div>
</template>

<style scoped>
@import "../../styles/feature-content.scss";
.hero-body {
  background: url("/img/service-banner/hosting-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
}
#hero-home {
  position: relative;
}
#hero-home:after {
  content: "\A";
  background-image: url("/img/service-banner/hosting-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>

<script>
export default {
  name: "Hosting"
}
</script>
